import Column from 'Components/Base/Column'
import Icon from 'Components/Base/Icon'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import ContentHeader from 'Pages/PatientProfile/components/ContentHeader'
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from 'hooks/hooks'
import useDocumentTitle from 'hooks/useDocumentTitle'
import useMessagingCenter from 'hooks/useMessagingCenter'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { getAccessToken } from 'reducers/authSlice'
import { setLoading } from 'reducers/tempSlice'
import styled, { useTheme } from 'styled-components'
import { Category, PatientMessage, TriaMessage } from 'types/MessagingCenter'

interface Props {
  isMobile: boolean
  sideMenuToggled: () => void
  setArchivedPatientMessages: (PatientMessages: PatientMessage[]) => void
  setArchivedTriaMessages: (TriaMessages: TriaMessage[]) => void
  archivedTriaMessages: TriaMessage[]
  archivedPatientMessages: PatientMessage[]
  messageCategories: Category[]
  setPatientMessages: (messages: PatientMessage[]) => void
}
export default function MessagingArchiveScreen({
  isMobile,
  sideMenuToggled,
  setArchivedPatientMessages,
  setArchivedTriaMessages,
  archivedPatientMessages,
  archivedTriaMessages,
  messageCategories,
  setPatientMessages,
}: Props) {
  const [currentPatientMessage, setCurrentPatientMessage] = useState<PatientMessage>(archivedPatientMessages[0])
  const [currentTriaMessage, setCurrentTriaMessage] = useState<TriaMessage>(archivedTriaMessages[0])
  const [filteredPatientMessages, setFilteredPatientMessages] = useState<PatientMessage[]>(archivedPatientMessages)
  const [filteredTriaMessages, setFilteredTriaMessages] = useState<TriaMessage[]>(archivedTriaMessages)
  const [displayPatientMessages, setDisplayPatientMessages] = useState<boolean>(true)
  const [searchValue, setSearchValue] = useState<string>('')
  const { setDocumentTitle } = useDocumentTitle()
  setDocumentTitle('Messaging Archive')

  const navigate = useNavigate()
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const accessToken = useAppSelector(getAccessToken)
  const { getTriaMessages, getPatientMessages, viewPatientMessage } = useMessagingCenter(accessToken!)

  useEffect(() => {
    dispatch(setLoading(true))
    getPatientMessages().then((res) => {
      setFilteredPatientMessages(res.data.patientInitiatedMessages.filter((m) => m.patientArchivedDate !== null || m.triaClosedDate !== null))
      setArchivedPatientMessages(res.data.patientInitiatedMessages.filter((m) => m.patientArchivedDate !== null || m.triaClosedDate !== null))
      setCurrentPatientMessage(res.data.patientInitiatedMessages.filter((m) => m.patientArchivedDate !== null || m.triaClosedDate !== null)[0])
      dispatch(setLoading(false))
    })
  }, [])

  useEffect(() => {
    dispatch(setLoading(true))
    getTriaMessages().then((res) => {
      setFilteredTriaMessages(res.data.triaInitiatedMessages.filter((m) => m.archivedDate !== null))
      setArchivedTriaMessages(res.data.triaInitiatedMessages.filter((m) => m.archivedDate !== null))
      dispatch(setLoading(false))
    })
  }, [])

  useEffect(() => {
    setSearchValue('')
    setFilteredPatientMessages(archivedPatientMessages)
    setFilteredTriaMessages(archivedTriaMessages)
  }, [displayPatientMessages])

  const selectPatientMessage = (message) => {
    setCurrentPatientMessage(message)
    if (currentPatientMessage.hasNewMessage) {
      currentPatientMessage.conversations.map((history) =>
        viewPatientMessage(history.patientInitiatedMessageHistoryPId).then(() => {
          getPatientMessages().then((res) => {
            refreshMessagesUponView(res)
          })
        }),
      )
    }
    if (message.hasNewMessage) {
      message.conversations.map((history) =>
        viewPatientMessage(history.patientInitiatedMessageHistoryPId).then(() => {
          getPatientMessages().then((res) => {
            refreshMessagesUponView(res)
          })
        }),
      )
    }
  }

  const selectTriaMessage = (message) => {
    setCurrentTriaMessage(message)
  }

  const refreshMessagesUponView = (res) => {
    setPatientMessages(res.data.patientInitiatedMessages)
    if (searchValue === '') {
      setArchivedPatientMessages(res.data.patientInitiatedMessages.filter((m) => m.patientArchivedDate !== null || m.triaClosedDate !== null))
      setFilteredPatientMessages(res.data.patientInitiatedMessages.filter((m) => m.patientArchivedDate !== null || m.triaClosedDate !== null))
    } else {
      setFilteredPatientMessages(
        res.data.patientInitiatedMessages.filter(
          (m) =>
            messageCategories
              .find((cat) => cat.patientInitiatedMessageCategoryPId === m.patientInitiatedMessageCategoryPId)
              ?.categoryName.toLowerCase()
              .includes(searchValue.toLowerCase()) &&
            (m.patientArchivedDate !== null || m.triaClosedDate !== null),
        ),
      )
    }
    navigate('')
  }

  const filterMessage = (searchValue) => {
    setSearchValue(searchValue)
    if (displayPatientMessages === true) {
      if (searchValue === '') {
        setFilteredPatientMessages(archivedPatientMessages)
      } else {
        setFilteredPatientMessages(
          archivedPatientMessages.filter(
            (m) =>
              messageCategories
                .find((cat) => cat.patientInitiatedMessageCategoryPId === m.patientInitiatedMessageCategoryPId)
                ?.categoryName.toLowerCase()
                .includes(searchValue.toLowerCase()) ||
              m.conversations.find((message) => message.body.toLowerCase().includes(searchValue.toLowerCase())),
          ),
        )
      }
    } else {
      if (searchValue === '') {
        setFilteredTriaMessages(archivedTriaMessages)
      } else {
        setFilteredTriaMessages(
          archivedTriaMessages.filter(
            (m) => m.messageType.toLowerCase().includes(searchValue.toLowerCase()) || m.body.includes(searchValue.toLowerCase()),
          ),
        )
      }
    }
  }

  return (
    <>
      <Row styles={{ justifyContent: 'space-between', alignItems: 'center' }}>
        {isMobile && (
          <div onClick={sideMenuToggled} style={{ cursor: 'pointer' }}>
            <Icon name="bars" color="#00677F" styles={{ marginBottom: 20 }} />
          </div>
        )}

        <ContentHeader title="Archive" icon="archive" color={theme.colors.primary1} />
        {isMobile && <div></div>}
      </Row>

      <Row>
        <div
          style={{
            cursor: 'pointer',
            backgroundColor: displayPatientMessages ? theme.colors.primary1 : '#D9D9D9',
            color: displayPatientMessages ? 'white' : 'black',
            width: 120,
            height: 40,
            textAlign: 'center',
            marginRight: 5,
            borderRadius: '5px 5px 0px 0px',
          }}
          onClick={() => setDisplayPatientMessages(true)}
        >
          <Typography styles={{ marginTop: 10, fontWeight: 'bold' }}>Inbox</Typography>
        </div>
        <div
          style={{
            cursor: 'pointer',
            backgroundColor: !displayPatientMessages ? theme.colors.primary1 : '#D9D9D9',
            color: !displayPatientMessages ? 'white' : 'black',
            width: 120,
            height: 40,
            textAlign: 'center',
            marginRight: 5,
            borderRadius: '5px 5px 0px 0px',
          }}
          onClick={() => setDisplayPatientMessages(false)}
        >
          <Typography styles={{ marginTop: 10, fontWeight: 'bold' }}>New From Tria</Typography>
        </div>
      </Row>
      <StyledSearchBar>
        <input
          value={searchValue}
          onChange={(e) => filterMessage(e.target.value)}
          placeholder="Search"
          style={{ width: '100%', height: 40, border: '1px solid #BDBDBD' }}
        />
        <Icon name={'search'} color={'grey'} styles={{ marginLeft: -30, marginTop: 10 }} />
      </StyledSearchBar>
      {displayPatientMessages ? (
        <div>
          <StyledCardContainer>
            <StyledMessageList>
              <div style={{ marginTop: 10 }}>
                {filteredPatientMessages.length > 0 &&
                  filteredPatientMessages.map(
                    (row, index) =>
                      row && (
                        <div
                          key={index}
                          style={
                            index ===
                            filteredPatientMessages.findIndex(
                              (message) => message.patientInitiatedMessagePId === currentPatientMessage?.patientInitiatedMessagePId,
                            )
                              ? {
                                  backgroundColor: 'rgba(0, 103, 127, 0.26)',
                                  width: '93%',
                                  margin: 'auto',
                                  padding: '10px',
                                }
                              : index % 2 !== 0
                              ? {
                                  backgroundColor: '#ffffff',
                                  width: '93%',
                                  margin: 'auto',
                                  padding: '10px',
                                }
                              : {
                                  backgroundColor: '#f2f2f2',
                                  width: '93%',
                                  margin: 'auto',
                                  padding: '10px',
                                }
                          }
                          className="careplan-detail-document-item"
                        >
                          <Row
                            styles={{
                              width: '99%',
                              margin: 'auto',
                              cursor: 'pointer',
                            }}
                            onClick={() => selectPatientMessage(row)}
                          >
                            <Row>
                              <Column>
                                <Row
                                  styles={{
                                    textAlign: 'left',
                                    width: '100%',
                                  }}
                                >
                                  <Typography variant="h3" size={15}>{`${
                                    messageCategories.find(
                                      (cat) => cat.patientInitiatedMessageCategoryPId === row?.patientInitiatedMessageCategoryPId,
                                    )?.categoryName
                                  }`}</Typography>
                                </Row>
                                <Row
                                  styles={{
                                    textAlign: 'left',
                                    width: '100%',
                                  }}
                                >
                                  {row.conversations && (
                                    <Typography>{`${row?.conversations[row?.conversations.length - 1]?.body.slice(0, 13)}${
                                      row?.conversations[row.conversations.length - 1]?.body.length > 13 ? '...' : ''
                                    }`}</Typography>
                                  )}
                                </Row>
                              </Column>
                            </Row>
                            <Column styles={{ marginLeft: 'auto', marginTop: 4 }}>
                              {row.hasNewMessage && (
                                <StyledBadge>
                                  <div style={{ marginBottom: 2 }}></div>
                                </StyledBadge>
                              )}
                              <Typography styles={{ marginRight: 10 }}>
                                {`${dayjs(row?.conversations[row?.conversations.length - 1].messageDate).format('M/D/YYYY')}`}
                              </Typography>
                            </Column>
                          </Row>
                        </div>
                      ),
                  )}
              </div>
            </StyledMessageList>
          </StyledCardContainer>
          {archivedPatientMessages.length > 0 && (
            <StyledProfileCardContainer>
              <StyledCurrentPatientMessageContainer>
                {/* //<Row> */}
                <div
                  style={{
                    width: '98%',
                    height: 'fit-content',
                    padding: 10,
                    margin: 'auto',
                    backgroundColor: theme.colors.primary1,
                    position: 'sticky',
                    top: 0,
                    borderTop: '10px solid white',
                  }}
                >
                  <Typography color="white" size={20} variant="h3">
                    {`Topic: ${
                      messageCategories.find(
                        (cat) => cat.patientInitiatedMessageCategoryPId === currentPatientMessage?.patientInitiatedMessageCategoryPId,
                      )?.categoryName
                    }`}
                  </Typography>
                  <Typography color="white" size={15} variant="h3">
                    {`${dayjs(currentPatientMessage?.dateOpened).format('M/D/YYYY')}`}
                  </Typography>
                </div>
                {currentPatientMessage &&
                  currentPatientMessage.conversations.map((message, index) => (
                    <div
                      key={index}
                      style={{ borderBottom: '1px solid #bdbdbd', width: '98%', padding: 10, margin: 'auto' }}
                      className="careplan-detail-document-item"
                    >
                      <Row
                        styles={{
                          textAlign: 'left',
                          width: '100%',
                          color: theme.colors.primary1,
                          marginBottom: 10,
                        }}
                      >
                        <Typography variant="h3" size={18}>
                          {`${dayjs(message.messageDate).format('M/D/YYYY')}`}
                        </Typography>
                      </Row>
                      <Row
                        styles={{
                          width: '99%',
                          margin: 'auto',
                          cursor: 'pointer',
                          marginBottom: 10,
                        }}
                      >
                        <Row>
                          <Column>
                            <Row
                              styles={{
                                textAlign: 'left',
                                width: '100%',
                                marginBottom: 15,
                              }}
                            >
                              <Typography size={18}>{`${message.body}`}</Typography>
                            </Row>
                            <Row
                              styles={{
                                textAlign: 'left',
                                width: '100%',
                                marginBottom: message.userTitle ? 5 : 0,
                              }}
                            >
                              <Typography variant="h3" font="bold" size={20}>{`${message.username}`}</Typography>
                            </Row>
                            {message.userTitle && (
                              <Row
                                styles={{
                                  textAlign: 'left',
                                  width: '100%',
                                }}
                              >
                                <Typography size={22}>{`${message.userTitle}`}</Typography>
                              </Row>
                            )}
                          </Column>
                        </Row>
                        <Column styles={{ marginLeft: 'auto', marginTop: 4 }}></Column>
                      </Row>
                    </div>
                  ))}

                <div style={{ marginLeft: '2%', marginTop: 15 }}>
                  {currentPatientMessage && currentPatientMessage.attachments.length > 0 && (
                    <Column>
                      <Row>
                        <Icon name="attachment" size="2x" color={theme.colors.primary1} />
                        <Typography variant="h2" styles={{ marginLeft: 5 }} color={theme.colors.primary1}>
                          Attachments:
                        </Typography>
                      </Row>
                      <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', marginLeft: isMobile ? -150 : 30 }}>
                        {currentPatientMessage.attachments.map((attachment, index) => {
                          return (
                            <div key={index} style={{ marginRight: 15 }}>
                              <a href={attachment.attachmentPath} target="_blank" rel="noreferrer">
                                {/* <img src={attachment.attachmentPath} alt='message attachment' style={{display: 'flex', width: 80, height: 60, marginLeft: 'auto', marginRight: 'auto'}}/> */}
                                <Row styles={{ display: 'flex', width: 'fit-content', marginLeft: 'auto', marginRight: 'auto' }}>
                                  <Typography styles={{ textDecoration: 'underline' }}>{attachment.filePath}</Typography>
                                </Row>
                              </a>
                            </div>
                          )
                        })}
                      </div>
                    </Column>
                  )}
                </div>
                <Typography color={theme.colors.primary1} size={18} styles={{ margin: 20 }}>
                  This conversation has been closed. Please initiate a new conversation if you need further assistance.
                </Typography>
              </StyledCurrentPatientMessageContainer>
            </StyledProfileCardContainer>
          )}
        </div>
      ) : (
        <div>
          <StyledCardContainer>
            <StyledMessageList>
              <div style={{ marginTop: 10 }}>
                {filteredTriaMessages.length > 0 &&
                  filteredTriaMessages.map(
                    (row, index) =>
                      row && (
                        <div
                          key={index}
                          style={
                            index ===
                            filteredTriaMessages.findIndex(
                              (message) => message.triaInitiatedMessagePId === currentTriaMessage?.triaInitiatedMessagePId,
                            )
                              ? {
                                  backgroundColor: 'rgba(0, 103, 127, 0.26)',
                                  width: '93%',
                                  margin: 'auto',
                                  padding: '10px',
                                }
                              : index % 2 !== 0
                              ? {
                                  backgroundColor: '#ffffff',
                                  width: '93%',
                                  margin: 'auto',
                                  padding: '10px',
                                }
                              : {
                                  backgroundColor: '#f2f2f2',
                                  width: '93%',
                                  margin: 'auto',
                                  padding: '10px',
                                }
                          }
                          className="careplan-detail-document-item"
                        >
                          <Row
                            styles={{
                              width: '99%',
                              margin: 'auto',
                              cursor: 'pointer',
                            }}
                            onClick={() => selectTriaMessage(row)}
                          >
                            <Row>
                              <Column>
                                <Row
                                  styles={{
                                    textAlign: 'left',
                                    width: '100%',
                                  }}
                                >
                                  <Typography variant="h3" size={15}>{`${row?.messageType}`}</Typography>
                                </Row>
                                <Row
                                  styles={{
                                    textAlign: 'left',
                                    width: '100%',
                                  }}
                                >
                                  <Column>
                                    {row.body.split('|').map((text, index) => {
                                      if (text.startsWith('-')) {
                                        return (
                                          <Row styles={{ marginLeft: 20 }} key={index}>
                                            <li style={{ fontFamily: 'Raleway' }}>{text.slice(1, text.length)}</li>
                                          </Row>
                                        )
                                      } else {
                                        return (
                                          <Row styles={{ marginBottom: index === row.body.split('|').length - 1 ? 0 : 10 }} key={index}>
                                            <Typography>{text}</Typography>
                                          </Row>
                                        )
                                      }
                                    })}
                                  </Column>
                                </Row>
                              </Column>
                            </Row>
                            <Column styles={{ marginLeft: 'auto', marginTop: 4 }}>
                              <Typography styles={{ marginRight: 10 }}>{`${dayjs(row?.messageDate).format('M/D/YYYY')}`}</Typography>
                            </Column>
                          </Row>
                        </div>
                      ),
                  )}
              </div>
            </StyledMessageList>
          </StyledCardContainer>
          {archivedTriaMessages.length > 0 && (
            <StyledProfileCardContainer>
              <StyledCurrentTriaMessageContainer>
                {/* //<Row> */}
                <div
                  style={{
                    width: '98%',
                    height: 'fit-content',
                    padding: 10,
                    margin: 'auto',
                    backgroundColor: theme.colors.primary1,
                    position: 'sticky',
                    top: 0,
                    borderTop: '10px solid white',
                  }}
                >
                  <Typography color="white" size={20} variant="h3">
                    {`Topic: ${currentTriaMessage?.messageType}`}
                  </Typography>
                  <Typography color="white" size={15} variant="h3">
                    {`${dayjs(currentTriaMessage?.messageDate).format('M/D/YYYY')}`}
                  </Typography>
                </div>
                <div style={{ width: '98%', padding: 10, margin: 'auto' }} className="careplan-detail-document-item">
                  <Row
                    styles={{
                      width: '99%',
                      margin: 'auto',
                      cursor: 'pointer',
                    }}
                  >
                    <Row>
                      <Column>
                        <Row
                          styles={{
                            textAlign: 'left',
                            width: '100%',
                          }}
                        >
                          <Column>
                            {currentTriaMessage &&
                              currentTriaMessage.body.split('|').map((text, index) => {
                                if (text.startsWith('-')) {
                                  return (
                                    <Row styles={{ marginLeft: 20 }}>
                                      <li style={{ fontFamily: 'Raleway' }}>{text.slice(1, text.length)}</li>
                                    </Row>
                                  )
                                } else {
                                  return (
                                    <Row styles={{ marginBottom: index === currentTriaMessage.body.split('|').length - 1 ? 0 : 10 }}>
                                      <Typography>{text}</Typography>
                                    </Row>
                                  )
                                }
                              })}
                          </Column>
                        </Row>
                      </Column>
                    </Row>
                    <Column styles={{ marginLeft: 'auto', marginTop: 4 }}></Column>
                  </Row>
                </div>
                <Typography color={theme.colors.primary1} size={18} styles={{ margin: 20 }}>
                  This conversation has been closed. Please initiate a new conversation if you need further assistance.
                </Typography>
              </StyledCurrentTriaMessageContainer>
            </StyledProfileCardContainer>
          )}
        </div>
      )}
    </>
  )
}
const StyledCardContainer = styled.div`
  @media only screen and (min-width: 1200px) {
    display: flex;
    flex-direction: row;
  }
`
const StyledMessageList = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid #bdbdbd;
  position: relative;
  overflow-y: scroll;
  @media only screen and (min-width: 1200px) {
    height: 445px;
    width: 34%;
  }

  @media only screen and (max-width: 1200px) {
    height: 270px;
    width: 100%;
    margin: auto;
  }
`

const StyledCurrentPatientMessageContainer = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid #bdbdbd;
  position: relative;
  overflow-y: scroll;
  @media only screen and (min-width: 1200px) {
    height: 545px;
    width: 130%;
    margin-left: -30%;
  }

  @media only screen and (max-width: 1200px) {
    height: 270px;
    width: 100%;
    margin-top: 5%;
  }
`

const StyledCurrentTriaMessageContainer = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid #bdbdbd;
  position: relative;
  overflow-y: scroll;
  @media only screen and (min-width: 1200px) {
    height: 545px;
    width: 130%;
    margin-left: -30%;
  }

  @media only screen and (max-width: 1200px) {
    height: 270px;
    width: 100%;
    margin-top: 5%;
  }
`

const StyledProfileCardContainer = styled.div`
  @media only screen and (min-width: 1200px) {
    flex-direction: column;
    margin-left: 51%;
    margin-top: -545px;
  }
`

const StyledSearchBar = styled.div`
  position: relative;
  display: flex;
  @media only screen and (min-width: 1200px) {
    height: 40px;
    width: 34%;
  }

  @media only screen and (max-width: 1200px) {
    height: 40px;
    width: 100%;
    margin: auto;
  }
`

const StyledBadge = styled.div`
  height: 10px;
  min-width: 10px;
  width: fit-content;
  margin-left: auto;
  border-radius: 10px;
  background-color: #e40046;
  display: flex;
  align-items: center;
  justify-content: center;
`
