import Button from 'Components/Base/Button'
import Column from 'Components/Base/Column'
import ResponsiveContainer from 'Components/Base/ResponsiveContainer'
import Typography from 'Components/Base/Typography'
import { useStopContext } from 'Pages/ProgramScreen'
import STOPPageContainer from 'Pages/STOPProgram/components/STOPPageContainer'
import ScaledText from 'Pages/STOPProgram/components/ScaledText'
import { sizes } from 'Styles/sizes'
import dayjs from 'dayjs'
import { useAppSelector } from 'hooks/hooks'
import React, { useEffect, useState } from 'react'
import { ProgressBar, Step } from 'react-step-progress-bar'
import { getProfile } from 'reducers/profileSlice'
import styled, { useTheme } from 'styled-components'
import { STOPActivity } from 'types/STOP'
import ActivityIcon from './components/ActivityIcon'

type Props = {
  onSetStarted: (activityId?: STOPActivity, summary?: boolean) => void
  completionDate: string
}
export default function IntroScreen({ onSetStarted, completionDate }: Props) {
  const theme = useTheme()
  const profile = useAppSelector(getProfile)
  const { currentActivity, activityStatus, currentActivityStep, setCurrentActivityStep } = useStopContext()
  const [available, setAvailable] = useState<boolean>(false)

  const activity1Status = activityStatus.find((x) => x.activityPId === 1)
  const activity2Status = activity1Status?.completedDate ? activityStatus.find((x) => x.activityPId === 2) : undefined
  const activity3Status =
    activity1Status?.completedDate && activity2Status?.completedDate ? activityStatus.find((x) => x.activityPId === 3) : undefined
  const activitiesComplete =
    activity3Status &&
    activity3Status?.completedDate !== null &&
    activity2Status &&
    activity2Status?.completedDate !== null &&
    activity1Status &&
    activity1Status?.completedDate !== null
  const activitiesExpired = activity1Status ? dayjs().isAfter(dayjs(activity1Status.availableDate).add(90, 'day')) : false
  const expirationDate = dayjs(activity1Status?.availableDate).add(90, 'day').format('MM/DD/YYYY hh:mm:ss a')
  console.log(activity2Status)

  useEffect(() => {
    setAvailable(dayjs(activityStatus.find((x) => x.activityPId === currentActivity + 1)?.availableDate) < dayjs())
  }, [currentActivity, activityStatus])

  const viewSummary = () => {
    onSetStarted(STOPActivity.MedicationOptions, true)
    setCurrentActivityStep(11)
  }

  return (
    <STOPPageContainer styles={{ marginTop: 25 }}>
      <Column styles={{ justifyContent: 'space-between', height: '100%', alignItems: 'center' }}>
        <Column styles={{ alignItems: 'center' }}>
          <StyledTypography>Tria Health’s</StyledTypography>
          <StyledSubtitle>Tobacco Cessation Program</StyledSubtitle>
          <StyledText>
            <ScaledText size={25}>
              This program consists of three at-home activities. Each activity takes an average of 15 minutes for you to complete, and you can
              complete the program at your own pace. The next activity will become available a week after completion of the previous activity.
            </ScaledText>
          </StyledText>
        </Column>
        <StyledColumn center>
          <StyledResponsiveContainer styles={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
            <ActivityIcon
              activityId={STOPActivity.HealthBenefits}
              text="Health Benefits of Quitting Tobacco"
              disabled={activitiesExpired}
              activityStatus={activity1Status}
              onStartActivity={onSetStarted}
            />
            <ActivityIcon
              activityId={STOPActivity.OvercomingObstacles}
              text="Overcoming the Obstacles"
              disabled={(!activitiesComplete && currentActivity < STOPActivity.OvercomingObstacles) || activitiesExpired}
              activityStatus={activity2Status}
              onStartActivity={onSetStarted}
            />
            <ActivityIcon
              activityId={STOPActivity.MedicationOptions}
              text="Medication Options & Benefits"
              disabled={(!activitiesComplete && currentActivity < STOPActivity.MedicationOptions) || activitiesExpired}
              activityStatus={activity3Status}
              onStartActivity={onSetStarted}
            />
          </StyledResponsiveContainer>
          <StyledProgress>
            <ProgressBar
              percent={
                activity1Status && activity1Status.completedDate === null
                  ? 0
                  : activity2Status && activity2Status.completedDate === null
                  ? 0
                  : activity2Status && activity2Status.completedDate !== null
                  ? 50
                  : 100
              }
              filledBackground={theme.colors.charts.inRange}
              width={500}
            >
              <Step transition="scale">
                {() => (
                  <ActivityIcon
                    activityId={STOPActivity.HealthBenefits}
                    text="Health Benefits of Quitting Tobacco"
                    disabled={activitiesExpired}
                    activityStatus={activity1Status}
                    onStartActivity={onSetStarted}
                  />
                )}
              </Step>
              <Step transition="scale">
                {() => (
                  <ActivityIcon
                    activityId={STOPActivity.OvercomingObstacles}
                    text="Overcoming the Obstacles"
                    disabled={(!activitiesComplete && currentActivity < STOPActivity.OvercomingObstacles) || activitiesExpired}
                    activityStatus={activity2Status}
                    onStartActivity={onSetStarted}
                  />
                )}
              </Step>
              <Step transition="scale">
                {() => (
                  <ActivityIcon
                    activityId={STOPActivity.MedicationOptions}
                    text="Medication Options & Benefits"
                    disabled={(!activitiesComplete && currentActivity < STOPActivity.MedicationOptions) || activitiesExpired}
                    activityStatus={activity3Status}
                    onStartActivity={onSetStarted}
                  />
                )}
              </Step>
            </ProgressBar>
          </StyledProgress>
        </StyledColumn>
        {!activitiesComplete && activitiesExpired && !activitiesComplete && (
          <Column center styles={{ alignItems: 'center' }}>
            <ScaledText size={25}>Activities are no longer available. Activities expired on {expirationDate}</ScaledText>
          </Column>
        )}

        {!activitiesComplete && !activitiesExpired && (
          <Column center styles={{ alignItems: 'center' }}>
            <ScaledText styles={{ textAlign: 'center', marginTop: 50 }} size={25}>
              You’ll need to complete all activities by {completionDate} to receive your completion certificate.
            </ScaledText>
            <Button
              text={currentActivityStep !== 0 ? 'Continue' : 'Get Started'}
              disabled={!available}
              styles={{ width: 170, backgroundColor: theme.colors.primary1, marginTop: 15 }}
              onClick={() => onSetStarted(currentActivity)}
            />
          </Column>
        )}
        {activitiesComplete && (
          <Column center styles={{ alignItems: 'center', textAlign: 'center', marginBottom: 25 }}>
            <ScaledText size={25} styles={{ marginBottom: 25 }}>
              You have completed activity 3 of 3 for the tobacco cessation program. We’ll send you an email confirming your program completion.
            </ScaledText>
            <Button text="View Summary" styles={{ backgroundColor: '#00A9CE' }} onClick={() => viewSummary()} />
          </Column>
        )}
      </Column>
    </STOPPageContainer>
  )
}

const StyledColumn = styled(Column)`
  min-width: 55%;
  align-items: center;
  margin-bottom: 75px;
`
const StyledText = styled.div`
  text-align: center;
  width: 100%;
  margin-top: 25px;
  margin-bottom: 15px;
  @media (${sizes.tablet}) {
    width: 70%;
  }
`
const StyledTypography = styled(Typography)`
  font-size: 25px;
  font-weight: bold;
  @media (${sizes.tablet}) {
    font-size: 70px;
  }
`
const StyledSubtitle = styled(StyledTypography)`
  text-align: center;
  margin-top: 0px;
  @media (${sizes.tablet}) {
    margin-top: -25px;
  }
`

const StyledResponsiveContainer = styled(ResponsiveContainer)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  @media (${sizes.tablet}) {
    display: none;
  }
`
const StyledProgress = styled.div`
  display: none;
  height: 150px;
  margin-top: 50px;
  @media (${sizes.tablet}) {
    display: flex;
  }
`
