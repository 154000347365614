import { STOPFormValues } from 'Pages/STOPProgram/ReflectionScreen'
import { AxiosResponse } from 'axios'
import dayjs from 'dayjs'
import qs from 'querystring'
import { PatientActivityValues } from 'types/STOP'
import { useApi } from './useAPI'

export default function usePrograms(token: string) {
  const TriaApi = useApi(token)

  const getSTOPStatus = async (callback?: Function) => {
    try {
      const response = await TriaApi.get(`api/programs/stop/status`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      const activities = response.data

      /* Removing any duplicates from testing */
      const sortedAndFiltered = activities
        .sort((a, b) => dayjs(b.availableDate).valueOf() - dayjs(a.availableDate).valueOf())
        .filter((value, index, self) => self.findIndex((v) => v.activityPId === value.activityPId) === index)

      if (callback) {
        callback(sortedAndFiltered)
      }

      return sortedAndFiltered
    } catch (error) {
      console.error('Error fetching STOP status:', error)
      throw error
    }
  }

  const getCertificate = async (guid: string, callback?: Function) => {
    const response: Promise<AxiosResponse<PatientActivityValues>> = TriaApi.get(`api/programs/stop/certificate/${guid}`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }).then((resp) => {
      callback && callback(resp)
      return response
    })
  }

  const postSTOPStatus = async (activityId: number, activityStep?: number, activityStart?: boolean, callback?: Function) => {
    const data = qs.stringify({
      ActivityPId: activityId + 1,
      LastActivityStepPId: activityStep ?? undefined,
      ActivityStart: activityStart,
    })
    const response = TriaApi.post(`api/programs/stop/status`, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })

    return response
  }

  const postReflectionResponses = async (activityId: number, values: Partial<STOPFormValues>, callback?: Function) => {
    const responses = Object.entries(values).map(([key, value]) => ({
      QuestionId: parseInt(key),
      Answer: value.toString(), // Convert answer to string if it's not already
    }))

    const data = {
      ActivityPId: activityId + 1,
      Responses: responses,
    }
    const response = TriaApi.post(`api/programs/stop/reflection`, data)

    return response
  }

  return {
    getSTOPStatus,
    getCertificate,
    postSTOPStatus,
    postReflectionResponses,
  }
}
